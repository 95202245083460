$page-width-desktop: 1132px;
$page-padding-side-desktop: 32px;
$page-padding-side-mobile: 24px;

@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");


$media-desktop: (min-width: 768px);
$media-mobile: (max-width: 768px);


body {
    font-family: "Roboto", sans-serif;
}

* {
    box-sizing: border-box;
}


a {
    color: inherit;
    text-decoration: inherit;
}


ul {
    margin: 0;
    padding: 0;
}


li {
    display: block;
    margin: 0;
    padding: 0;
}


img {
    object-fit: cover;
}


header {
    width: $page-width-desktop + $page-padding-side-desktop * 2;
    height: 213px;
    margin: auto;
    padding: 0 $page-padding-side-desktop;
    background-color: #FFFFFF;
    position: relative;
    @media #{$media-mobile} {
        width: 100%;
        height: fit-content;
        padding: 0 $page-padding-side-mobile;
    }

    > .left-menu {
        display: inline-block;
        vertical-align: top;
        width: fit-content;
        height: 100%;
        margin-right: 120px;
        padding: 64px 0 120px 0;
        @media #{$media-mobile} {
            padding: 40px 0 60px 0;
            height: calc(24px + 40px + 60px);
            margin-right: 0;
        }

        > a {

            > img {
                height: 100%;
            }
        }
    }

    > .center-menu {
        display: inline-block;
        vertical-align: top;
        padding: 68px 0;
        @media #{$media-mobile} {
            display: none;
        }

        > div {
            display: inline-block;
            margin-right: 54px;
            font-size: 18px;
            line-height: 21px;
            color: #B5B5B5;
            vertical-align: top;
            &:hover {
                color: #E84D65;
            }

            & > ul {
                margin: 22px -120px 0 0;
                width: 120px;

                & > li {
                    margin-bottom: 6px;
                    font-size: 12px;
                    line-height: 14px;
                    width: fit-content;
                    color: #B5B5B5;
                    &:hover {
                        color: #E84D65;
                    }
                }
            }
            &:not(:hover) > ul {
                display: none;
            }
        }
    }

    > .right-menu {
        display: none;
        @media #{$media-mobile} {
            display: initial;
            float: right; 
        }

        > span {
            display: block;
            margin-top: 28px;
            width: 48px;
            height: 48px;
            background-image: url('./images/button-menu-outline-36px.png');
            background-size: contain;
            cursor: pointer;
        }
    }

    > .slide-menu {
        display: none;
        background-color: #E84D36;
        position: fixed;
        top: 0;
        right: 0;
        height: 100%;
        width: calc(25% + 180px);
        z-index: 100;
        border-radius: 12px 0 0 12px;
        padding: 24px 24px 36px 24px;
        @media #{$media-mobile} {
            &.slide-menu--open {
                display: initial;
            }
        }

        > span {
            display: none;
            float: right;
            @media #{$media-mobile} {
                display: block;
                width: 48px;
                height: 48px;
                background-image: url('./images/button-close-outline-inverted-48px.png');
                background-size: contain;
                cursor: pointer;
            }
        }

        > div {
            margin-bottom: 12px;
            &:first-of-type {
                margin-top: 170px;
            }

            > a,
            > span {
                font-size: 24px;
                line-height: 28px;
                color: #FFFFFF;
                padding: 6px 0 6px 0;
                display: block;
                cursor: pointer;
                text-transform: uppercase;

                > span {
                    float: right;
                    width: 24px;
                    height: 24px;
                    margin: ((28px - 24px) / 2) 0;
                    &.link {
                        background-image:  url('./images/button-link-outline-inverted-24px.png');
                        background-size: contain;
                    }
                    &.more {
                        background-image:  url('./images/button-plus-outline-inverted-24px.png');
                        background-size: contain;
                    }
                    &.close {
                        background-image:  url('./images/button-close-fill-inverted-24px.png');
                        background-size: contain;
                    }
                }
            }

            > ul {
                display: none;
                margin: 6px 0 42px 3px;
                border-left: 1px solid #FFFFFF;
                
                > li {
                    font-size: 14px;
                    line-height: 16px;
                    font-weight: 400;
                    color: #FFFFFF;
                    margin-left: 24px;
                    &:not(:last-child) {
                        margin-bottom: 12px;
                    }
                }
            }
        }

        > .slide-menu__footer {
            position: absolute;
            bottom: 36px;
            margin-bottom: 0;
            width: calc(100% - 24px * 2);
            font-size: 12px;
            line-height: 14px;
            font-weight: 400;
            color: #FFFFFF;
            
            > div {
                width: 100%;
                &:first-child {
                    margin-bottom: 4px;
                }
                &:last-child {
                    margin-top: 29px;

                    > span {
                        float: right;
                        width: 14px;
                        height: 10px;
                        border-bottom: 1px solid #FFFFFF;
                    }
                }
            }
        }
    }
}


footer {
    width: $page-width-desktop + $page-padding-side-desktop * 2;
    height: 209px;
    margin: 240px auto auto auto;
    padding: 0 $page-padding-side-desktop;
    background-color: #FFFFFF;
    position: relative;
    @media #{$media-mobile} {
        width: 100%;
        margin-top: 100px;
        height: 129px;
    }

    > div {
        margin-bottom: 4px;
        font-size: 12px;
        line-height: 14px;
        color: #7C7C7C;
        &:last-child {
            margin-top: 29px;
        }
    }
}


.content-area {
    width: $page-width-desktop + $page-padding-side-desktop * 2;
    margin: auto;
    margin-bottom: 100px;
    padding: 0 $page-padding-side-desktop;
    @media #{$media-mobile} {
        width: 100%;
        padding: 0 $page-padding-side-mobile;
    }
}


.main {
    &__images {
        width: 100vw;
        height: 60vh;
        margin: 0 calc((#{$page-width-desktop} - 100vw) / 2);
        overflow-x: hidden;
        position: relative;
        @media (max-width: ($page-width-desktop + 2 * $page-padding-side-desktop)) {
            width: $page-width-desktop + 2 * $page-padding-side-desktop;
            margin: 0 (-$page-padding-side-desktop);
        }
        @media #{$media-mobile} {
            display: none;
        }

        > div {
            width: fit-content;
            height: 100%;
            padding: 0 calc((100vw - #{$page-width-desktop}) / 2);
            white-space: nowrap;
            position: relative;
            top: 0;
            left: 0;
            @media (max-width: ($page-width-desktop + 2 * $page-padding-side-desktop)) {
                padding: 0 $page-padding-side-desktop;
            }

            > img {
                display: inline-block;
                height: 100%;
                border-radius: 18px;
                vertical-align: bottom;
                &:not(:last-child) {
                    margin-right: 24px;
                }

                &.image-placeholder {
                    width: 1000px;
                }
            }
        }
    }

    &__intro {
        display: none;
        @media #{$media-mobile} {
            display: initial;
        }

        &__row {

            &__images {
                margin-bottom: 36px;

                > img {
                    display: inline-block;
                    line-height: 0;
                    border-radius: 12px;

                    &:first-child {
                        width: 100%;
                        height: 173px;
                        margin-bottom: 12px;
                    }
                    &:nth-child(2) {
                        width: calc((100% - 12px) / 2);
                        height: 211px;
                        margin-right: 12px;
                    }
                    &:nth-child(3) {
                        width: calc((100% - 12px) / 2);
                        height: 211px;
                    }
                }
            }

            &__title {
                font-size: 24px;
                line-height: 24px;
                color: #E84D36;
                font-weight: 500;
                margin-bottom: 12px;
            }

            &__description {
                font-size: 14px;
                line-height: 20px;
                color: #4C4C4C;
                font-weight: 300;
                position: relative;

                &__link {
                    position: absolute;
                    bottom: 0;
                    right: 0;
                    width: 48px;
                    height: 48px;
                    background-image:  url('./images/button-link-fill-36px.png');
                    background-size: contain;
                }
            }
        }
    }

    &__modal {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 10;

        &__background {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background-color: rgba(0, 0, 0, 0.4);
            z-index: -1;
        }

        &__body {
            position: relative;
            margin: calc((100vh - 300px) / 2) auto;
            width: 424px;
            height: 300px;
            z-index: 10;
            background-color: #FFFFFF;
            padding: 24px;
            border-radius: 12px;
            @media #{$media-mobile} {
                margin: calc((100vh - 372px) / 2) auto;
                width: calc(100vw - 24px * 2);
                height: 372px;
            }

            &__title {
                margin-top: 6px;
                margin-bottom: 42px;
                font-size: 24px;
                line-height: 24px;
                color: #E84D36;
                font-weight: 500;
            }

            &__content {
                font-size: 14px;
                line-height: 20px;
                color: #4C4C4C;
                font-weight: 300;
                text-align: justify;
                height: 120px;
            }

            & > span {
                position: absolute;
                top: 24px;
                right: 24px;
                display: block;
                width: 36px;
                height: 36px;
                background-image: url('./images/button-close-outline.png');
                background-size: contain;
                cursor: pointer;
            }

            & > a {
                text-align: center;
                position: absolute;
                left: (424px - 36px) / 2;
                right: (424px - 36px) / 2;
                bottom: 24px;
                display: block;
                width: 36px;
                height: 36px;
                background-image: url('./images/button-link-fill.png');
                background-size: contain;
                cursor: pointer;
            }

            &::before {
                content: '';
                display: block;
                position: absolute;
                bottom: -12px;
                height: 12px;
                left: 12px;
                right: 12px;
                z-index: -1;
                background-color: #E2E0E0;
                border-radius: 0 0 12px 12px;
            }
        }
    }
}


.about {
    &__row {
        display: flex;
        flex-direction: column;
        align-items: stretch;

        &__images {
            display: block;
            height: 500px;
            margin-bottom: 72px;
            position: relative;
            @media #{$media-mobile} {
                order: 1;
                height: initial;
                margin-top: 36px;
                margin-bottom: 0;
            }
    
            & > img {
                height: 100%;
                border-radius: 18px;
                @media #{$media-mobile} {
                    display: block;
                    height: initial;
                    &:not(:first-child) {
                        margin-top: 12px;
                    } 
                }
                &.about__row__images__small {
                    width: 398px;
                    @media #{$media-mobile} {
                        width: 100%;
                    }
                }
                &.about__row__images__big {
                    width: 710px;
                    @media #{$media-mobile} {
                        width: 100%;
                    }
                }
                &:first-child {
                    margin-right: 24px;
                }
            }
    
            & > span {
                position: absolute;
                right: -18px;
                top: (500px - 36px) / 2;
                width: 36px;
                height: 36px;
                border-radius: 18px;
                background-color: #E84D36;
                @media #{$media-mobile} {
                    right: initial;
                    top: initial;
                    bottom: -18px;
                    left: calc((100% - 36px) / 2);
                }
    
                &::after {
                    display: block;
                    content: '';
                    width: 14px;
                    height: 14px;
                    margin: (36px - 14px) / 2;
                    background-image: url('./images/icon-plus.png');
                    background-size: contain;
                }
            }
        }

        &__title {
            font-size: 18px;
            line-height: 24px;
            font-weight: 500;
            color: #E84D36;
            margin-bottom: 36px;
            @media #{$media-mobile} {
                font-size: 24px;
                line-height: 24px;
                margin-bottom: 24px;
                text-transform: uppercase;
                display: block;
            }
        }
    
        &__description {
            font-size: 14px;
            line-height: 20px;
            color: #4C4C4C;
            font-weight: 300px;
            text-align: justify;
        }
    }
}


.gallery {
    &__images {
        margin-right: -24px;
        margin-bottom: 120px - 24px;

        & > div {
            display: inline-block;
            height: 500px;
            margin: 0 24px 24px 0;
            border-radius: 18px;
            vertical-align: bottom;
            position: relative;
            overflow: hidden;
            cursor: pointer;
            &:nth-child(4n+1),
            &:nth-child(4n) {
                width: 398px;
            }
            &:nth-child(4n+2),
            &:nth-child(4n+3) {
                width: 710px;
            }
            @media #{$media-mobile} {
                width: initial !important;
                height: 410px;
            }

            & > img {
                width: 100%;
                height: 100%;
            }

            & > div {
                display: none;
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                padding: 36px;
                background-color: rgba(255, 0, 0, 0.2);

                & > div:first-child {
                    font-size: 16px;
                    line-height: 19px;
                    color: #FFFFFF;
                    font-weight: bold;
                }
                & > div:nth-child(2) {
                    font-size: 10px;
                    line-height: 11px;
                    color: #FFFFFF;
                }
            }
            &:hover > div {
                display: initial;
            }
        }
    }

    &__pages {
        width: 100%;
        text-align: center;

        & > div {
            display: inline-block;
            font-size: 18px;
            line-height: 21px;
            color: #B5B5B5;
            cursor: pointer;
            &:not(:last-child) {
                margin-right: 12px;
            }
            &.current {
                color: #E84D36;
            }
        }
    }

    &__modal {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 10;
        display: flex;
        justify-content: center;
        align-items: center;

        &__background {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background-color: rgba(0, 0, 0, 0.4);
            z-index: -1;
        }

        &__content {
            position: relative;
            width: fit-content;
            height: calc(100% - (60px + 10vh) * 2);
            text-align: center;
            z-index: 10;
            @media #{$media-mobile} {
                width: calc(100% - 36px * 2);
                height: fit-content;
            }

            > img {
                height: 100%;
                border-radius: 18px;
                @media #{$media-mobile} {
                    width: 100%;
                    height: initial;
                }
            }

            > span {
                position: absolute;
                right: -18px;
                top: -18px;
                width: 36px;
                height: 36px;
                border-radius: 18px;
                background-color: #E84D36;
                cursor: pointer;

                &::after {
                    display: block;
                    content: '';
                    width: 14px;
                    height: 14px;
                    margin: (36px - 14px) / 2;
                    background-image: url('./images/icon-plus.png');
                    background-size: contain;
                    transform: rotate(45deg);
                }
            }
        }
    }
}


.notice {
    &__list {
        position: relative;

        > li {
            padding: 36px;
            border-radius: 18px;
            background-color: #F8F8F8;
            &:not(:last-of-type) {
                margin-bottom: 24px;
            }

            > div:first-child {
                font-size: 16px;
                line-height: 19px;
                color: #4C4C4C;
                font-weight: bold;
                margin-bottom: 6px;
            }

            > div:nth-child(2) {
                font-size: 10px;
                line-height: 11px;
                color: #4C4C4C;
                margin-bottom: 36px;
            }

            > div:nth-child(3) {
                font-size: 14px;
                line-height: 20px;
                font-weight: 300;
                text-align: justify;

                a {
                    color: #E84D36;
                }
            }
        }

        & > div:last-child {
            position: absolute;
            bottom: -(36px / 2);
            left: calc((100% - 36px) / 2);
            width: 36px;
            height: 36px;
            border-radius: 18px;
            background-color: #E84D36;
            cursor: pointer;

            &::after {
                display: block;
                content: '';
                width: 14px;
                height: 14px;
                margin: (36px - 14px) / 2;
                background-image: url('./images/icon-plus.png');
                background-size: contain;
            }
        }
    }
}


.how-to-use {
    &__intro {
        display: flex;
        flex-direction: column;
        align-items: stretch;

        &__images {
            height: 499px;
            margin-bottom: 72px;
            @media #{$media-mobile} {
                order: 1;
                margin-top: 36px;
                margin-bottom: 0;
                height: initial;
            }
    
            & > img {
                height: 100%;
                border-radius: 18px;
                @media #{$media-mobile} {
                    height: initial;
                    display: block;
                    &:not(:first-child) {
                        margin-top: 12px;
                    }
                }
                &.how-to-use__intro__images__small {
                    width: 398px;
                    @media #{$media-mobile} {
                        width: 100%;
                    }
                }
                &.how-to-use__intro__images__big {
                    width: 710px;
                    @media #{$media-mobile} {
                        width: 100%;
                    }
                }
                &:first-child {
                    margin-right: 24px;
                }
            }
        }

        &__description {
            height: 100px;
            @media #{$media-mobile} {
                height: initial;
            }

            &__logo {
                display: inline-block;
                width: 84px;
                height: 100%;
                @media #{$media-mobile} {
                    display: none;
                }
    
                &--workshop {
                    width: 122px;
                    height: 128.5px;
                    margin-top: 72px - 128.5px;
                }
            }
    
            &__line {
                display: inline-block;
                width: 0;
                height: 100%;
                margin: 0 24px;
                border-right: 1px solid #E6E6E6;
                @media #{$media-mobile} {
                    display: none;
                }
            }

            &__title {
                font-size: 24px;
                line-height: 24px;
                font-weight: 500;
                color: #E84D36;
                margin-bottom: 12px;
                display: none;
                @media #{$media-mobile} {
                    display: block;
                }
            }
    
            &__content {
                display: inline-block;
                vertical-align: top;
                width: 760px;
                height: 100%;
                font-size: 14px;
                line-height: 20px;
                font-weight: 300;
                color: #4C4C4C;
                @media #{$media-mobile} {
                    width: initial;
                }
            }
        }
    }

    &__map {
        border-radius: 0px;
        width: 100%;
    }

    &__people {
        &__row {
            border-bottom: 1px solid #E6E6E6;
            margin-bottom: 40px;

            & > div {
                display: inline-block;
                width: 50%;
                @media #{$media-mobile} {
                    width: 100%;
                }

                & > img {
                    vertical-align: bottom;
                    width: 220px;
                    height: 300px;
                    background-color: #ffffff;
                    @media #{$media-mobile} {
                        width: 140px;
                        height: 172px;
                        display: inline-block;
                    }
                }

                & > div {
                    display: inline-block;
                    vertical-align: bottom;
                    margin-left: 60px;
                    margin-bottom: 42px;
                    @media #{$media-mobile} {
                        display: inline-block;
                        margin-left: 12px;
                        margin-bottom: 12px;
                        width: calc(100% - 12px - 140px);
                    }

                    & > div:first-child {
                        font-size: 24px;
                        line-height: 28px;
                        color: #4C4C4C;
                        font-weight: 500;
                        margin-bottom: 4px;
                    }

                    & > div:nth-child(2) {
                        font-size: 14px;
                        line-height: 16px;
                        color: #B5B5B5;
                    }

                    & > ul {
                        margin-top: 18px;

                        & > li {
                            display: block;
                            height: 26px;
                            width: fit-content;
                            border-radius: 13px;
                            background-color: #F8F8F8;
                            padding: 6px 12px;
                            font-size: 12px;
                            line-height: 14px;
                            color: #B5B5B5;
                            font-weight: 400;
                            &:not(:last-child) {
                                margin-bottom: 6px;
                            }

                            & > span {
                                display: inline-block;
                                height: 26px;
                                margin: -6px 12px -6px -12px;
                                padding: 6px 12px;
                                border-radius: 13px;
                                background-color: #E84D36;
                                font-size: 12px;
                                line-height: 14px;
                                color: #FFFFFF;
                                font-weight: 500;
                                text-align: center;
                            }
                        }
                    }

                    & > div:last-child {
                        font-size: 12px;
                        line-height: 14px;
                        color: #BDBDBD;
                        margin-top: 24px;

                        & > div:not(:last-child) {
                            margin-bottom: 4px;
                        }
                    }
                }
            }
        }
    }
}


.equipment {
    &__filter {
        margin-bottom: 28px;

        & > div {
            display: inline-block;
            height: 32px;
            border-radius: 16px;
            padding: 8px 12px;
            margin-right: 8px;
            margin-bottom: 8px;
            background-color: #F8F8F8;
            font-size: 14px;
            line-height: 16px;
            color: #B5B5B5;
            cursor: pointer;
            &.active {
                background-color: #E84D36;
                color: #FFFFFF;
            }
        }
    }

    &__list {
        margin-right: -40px;
        @media #{$media-mobile} {
            margin-right: initial;
        }

        & > a,
        & > a > li {
            @media #{$media-mobile} {
                width: 100%;
            }
        }

        & li {
            display: inline-block;
            margin-right: 36px;
            margin-bottom: 36px;
            position: relative;
            cursor: pointer;

            & > div {
                width: 354px;
                height: 198px;
                border-radius: 18px;
                padding-left: 36px;
                padding-top: 126px;
                background-color: #F8F8F8;
                position: relative;
                overflow: hidden;
                @media #{$media-mobile} {
                    width: 100%;
                }

                & > div:first-child {
                    font-size: 16px;
                    line-height: 19px;
                    color: #4D4D4D;
                    font-weight: bold;
                    margin-bottom: 6px;
                }

                & > div:nth-child(2) {
                    font-size: 10px;
                    line-height: 11px;
                    color: #B3B3B3;
                }

                & > img {
                    position: absolute;
                    width: 200px;
                    right: -90px;
                    top: 20px;
                }
            }
            &:hover > div {
                &::after {
                    display: block;
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 100%;
                    width: 100%;
                    background-color: rgba(255, 0, 0, 0.2);
                }
            }

            & > span {
                position: absolute;
                top: (198px - 36px) / 2;
                right: -18px;
                width: 36px;
                height: 36px;
                border-radius: 18px;
                background-color: #E84D36;

                &::after {
                    display: block;
                    content: '';
                    width: 14px;
                    height: 14px;
                    margin: (36px - 14px) / 2;
                    background-image: url('./images/icon-plus.png');
                    background-size: contain;
                }
            }
        }
    }
}


.title {
    font-size: 18px;
    line-height: 24px;
    color: #7C7C7C;
    font-weight: 500;
    margin-bottom: 36px;
    &--highlighted {
        color: #E84D36;
    }
}


.photo {
    background-color: #F6F6F6;
}


.image-placeholder {
    background-color: #EEEEEE;
    width: 100%;
    height: 500px;
}
.image-placeholder2 {
    //background-color: #EEEEEE;
    width: 100%;
    //height: 500px;
}

.matterport {
    width: 100%;
    height: 645px;
    border-radius: 18px;
    @media #{$media-mobile} {
        height: 182px;
    }
}


.divider {
    width: 100%;
    height: 0;
    border-bottom: 1px solid #E6E6E6;
    margin: 120px 0 54px 0;
    @media #{$media-mobile} {
        margin: 48px 0 36px 0;
    }
}
